import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import globalComponent from './plugins/global-components'

import { defineRule, configure } from 'vee-validate'
import { localize, setLocale } from '@vee-validate/i18n'
import ru from '@vee-validate/i18n/dist/locale/ru.json'
import uz from '@vee-validate/i18n/dist/locale/uz.json'
import AllRules from '@vee-validate/rules'

import Toast from '@/plugins/vue-toastification'
import '@/plugins/axios'

require('waypoints/lib/noframework.waypoints.min')

Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule])
})

configure({
  generateMessage: localize({
    ru, uz
  }),
  validateOnInput: true
})

setLocale('ru')

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Toast)
app.use(globalComponent)
app.mount('#app')
export { app }
