import { computed, watch, ref } from 'vue'

export default function useAuthStore () {
  // state
  const user = ref({
    token: null,
    userData: null
  })

  // persist state
  if (localStorage.getItem('user')) {
    user.value = JSON.parse(localStorage.getItem('user'))
  }

  watch(
    user,
    (userValue) => localStorage.setItem('user', JSON.stringify(userValue)),
    { deep: true }
  )

  // getters
  const isAuthenticated = computed(() => user.value.token !== null)
  const userData = computed(() => user.value.userData)
  const token = computed(() => user.value.token)

  // actions
  const login = (userData) => {
    user.value.token = userData.token
    user.value.userData = userData
  }

  const logout = () => {
    user.value.token = null
    user.value.userData = null
    localStorage.removeItem('user')
  }

  return {
    isAuthenticated,
    userData,
    token,
    login,
    logout
  }
}
