import { createRouter, createWebHistory } from 'vue-router'
import useAuthStore from '@/store/auth'

const authchildRoutes = (prop, mode = false) => [
  {
    path: 'signin',
    name: prop + '.signin',
    meta: { auth: true, name: 'SignIn' },
    component: () => import('../views/pages/Authentication/SignIn')
  },
  {
    path: 'recover-password',
    name: prop + '.recoverPassword',
    meta: { auth: true, name: 'Recover password' },
    component: () => import('../views/pages/Authentication/RecoverPassword')
  }
]

const defaultchildRoutes = (prop, mode = false) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true, name: 'dashboard' },
    component: () => import('../views/main/dashboard')
  },
  {
    path: 'users',
    name: prop + '.users',
    meta: { auth: true, name: 'users' },
    component: () => import('../views/pages/Users/Index')
  },
  {
    path: 'orders',
    name: prop + '.orders',
    meta: { auth: true, name: 'orders' },
    component: () => import('../views/pages/Orders/Index')
  },
  {
    path: 'statuses',
    name: prop + '.statuses',
    meta: { auth: true, name: 'statuses' },
    component: () => import('../views/pages/Statuses/Index')
  },
  {
    path: 'shops',
    name: prop + '.shops',
    meta: { auth: true, name: 'shops' },
    component: () => import('../views/pages/Shops/Index')
  },
  {
    path: 'shops/edit/:id',
    name: prop + '.shops.edit',
    meta: { auth: true, name: 'shops.edit' },
    component: () => import('../views/pages/Shops/Edit')
  },
  {
    path: 'countries',
    name: prop + '.countries',
    meta: { auth: true, name: 'countries' },
    component: () => import('../views/pages/Countries/Index')
  },
  {
    path: 'currencies',
    name: prop + '.currencies',
    meta: { auth: true, name: 'currencies' },
    component: () => import('../views/pages/Currencies/Index')
  },
  {
    path: 'countries/edit/:id',
    name: prop + '.countries.edit',
    meta: { auth: true, name: 'countries.edit' },
    component: () => import('../views/pages/Countries/Edit')
  },
  {
    path: 'categories',
    name: prop + '.categories',
    meta: { auth: true, name: 'categories' },
    component: () => import('../views/pages/Categories/Index.vue')
  },
  {
    path: 'flights',
    name: prop + '.flights',
    meta: { auth: true, name: 'flights' },
    component: () => import('../views/pages/Flights/Index.vue')
  },
  {
    path: 'admin',
    name: prop + '.admin',
    meta: { auth: true, name: 'admin' },
    component: () => import('../views/pages/admin')
  },
  {
    path: 'buttons-main',
    name: prop + '.buttons-main',
    meta: { auth: true, name: 'buttons-main' },
    component: () => import('../views/pages/Buttons/Main/Index')
  },
  {
    path: 'buttons-profile',
    name: prop + '.buttons-profile',
    meta: { auth: true, name: 'buttons-profile' },
    component: () => import('../views/pages/Buttons/Profile/Index')
  },
  {
    path: 'call-center',
    name: prop + '.buttons-change-language',
    meta: { auth: true, name: 'buttons-change-language' },
    component: () => import('../views/pages/Buttons/ChangeLanguage/Index')
  },
  {
    path: 'margin',
    name: prop + '.margin',
    meta: { auth: true, name: 'margin' },
    component: () => import('../views/pages/Buttons/Profit-Margin/Index')
  },
  // {
  //   path: 'buttons-main',
  //   name: prop + '.buttons-main',
  //   meta: { auth: true, name: 'buttons-main' },
  //   component: () => import('../views/pages/Buttons/Main')
  // },
  // Widget pages
  {
    path: 'widgetbasic',
    name: prop + '.widgetbasic',
    meta: { auth: true, name: 'Widget Basic' },
    component: () => import('../views/Elements/widget/WidgetBasic')
  },
  {
    path: 'widgetcard',
    name: prop + '.widgetcard',
    meta: { auth: true, name: 'Widget Card' },
    component: () => import('../views/Elements/widget/WidgetCard')
  },
  {
    path: 'widgetchart',
    name: prop + '.widgetchart',
    meta: { auth: true, name: 'Widget Chart' },
    component: () => import('../views/Elements/widget/WidgetChart')
  },
  {
    path: 'privacypolicy',
    name: prop + '.privacypolicy',
    meta: { auth: true, name: 'privacypolicy' },
    component: () => import('../views/Elements/Extra/PrivacyPolicy')
  },
  {
    path: 'termsofservice',
    name: prop + '.termsofservice',
    meta: { auth: true, name: 'TermsOfService' },
    component: () => import('../views/Elements/Extra/TermsOfService')
  }
]

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: '/error-404'
  },
  {
    path: '/',
    name: 'landing',
    // redirect: { name: 'auth' },
    meta: { auth: true, name: 'home' },
    component: () => import('../views/main/landing')
  },
  {
    path: '/admin',
    name: 'home',
    component: () => import('../layouts/default'),
    children: defaultchildRoutes('default')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../layouts/auth'),
    children: authchildRoutes('auth')
  },
  // Error Pages
  {
    path: '/error-404',
    name: 'error404',
    meta: { auth: true, name: 'Error404' },
    component: () => import('../views/pages/Utilities/Error404')
  },
  {
    path: '/error-500',
    name: 'error500',
    meta: { auth: true, name: 'Error500' },
    component: () => import('../views/pages/Utilities/Error500')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const { isAuthenticated } = useAuthStore()
  if (!isAuthenticated.value && to.name !== 'auth.signin' && to.name !== 'landing' && to.name !== 'auth.recoverPassword') {
    next({ name: 'auth.signin' })
  } else {
    next()
  }
})

export default router
