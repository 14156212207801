import axios from 'axios'
import { BASE_URL } from '@/utils/constants'
import useAuthStore from '@/store/auth'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

const config = {
  baseURL: BASE_URL
}

const api = axios.create(config)

api.interceptors.request.use((request) => {
  const authStore = useAuthStore()

  if (authStore.isAuthenticated.value) {
    axios.defaults.headers.common.Authorization = `Bearer ${authStore.token.value}`
    request.headers.Authorization = `Bearer ${authStore.token.value}`
  }

  return request
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const authStore = useAuthStore()
    const router = useRouter()
    const toast = useToast()

    if (error.response.status === 401) {
      toast.error(error.response.data.message)

      authStore.logout()
      router.push({ name: 'auth.signin' })

      return Promise.reject(error)
    }

    return Promise.reject(error)
  })

export default api
